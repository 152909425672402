import cn from 'classnames';
import React from 'react';
import type {LinksFunction} from '@remix-run/server-runtime';

import type {Handle} from '@/types';
import Disclaimers from '@/components/shared/Disclaimers/Disclaimers';
// @todo Migrate Footer off brochureV2 components and move to shared
import PageLayout from '@/components/shared/Page/PageLayout';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import HeroFloatingImage from '@/components/base/sections/Hero/HeroFloatingImage';
import Section from '@/components/base/layouts/Section/Section';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import CardGrid from '@/components/base/layouts/CardGrid/CardGrid';
import type {CardProps} from '@/components/base/modules/Card/Card';
import Card from '@/components/base/modules/Card/Card';
import Button from '@/components/base/elements/Button/Button';
import ComparisonTable from '@/components/shared/ComparisonTable/ComparisonTable';
import Testimonials from '@/components/base/sections/Testimonial/Testimonials';
import FullWidthCard from '@/components/base/modules/FullWidthCard/FullWidthCard';
import Faq from '@/components/base/modules/Faq/Faq';
import type {HeadingGroupProps} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import Link from '@/components/base/elements/Link/Link';
import type {TestimonialProps} from '@/components/base/sections/Testimonial/types';
import type {AccordionProps} from '@/components/base/modules/Accordion/Accordion';
import useIntlLocale from '@/hooks/useIntlLocale';
import {ColorSchemeEnum, Theme} from '@/enums';
import WistiaVideoPlayer from '@/components/shared/WistiaVideoPlayer';
import Typography from '@/components/base/elements/Typography/Typography';
import SideBySide from '@/components/base/modules/SideBySide/SideBySide';
import LogoGroupMarquee from '@/components/base/modules/LogoGroupMarquee/LogoGroupMarquee';
import customStyleSheet from '@/stylesheets/financial-services/styles.css?url';
import FinancialServicesHeader from '@/components/pages/financial-services/Header';
import Header from '@/components/brochureV2/section/Header/Header';
import Footer from '@/components/brochureV2/section/Footer/Footer';

type StatsMetric = HeadingGroupProps & {
  rawMetric?: number;
  minimumSignificantDigits?: number;
};

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: customStyleSheet}];
};

/**
 * Append UTM params with a localized `utm_content` value to a URL
 */
export function getUrlWithUtm(url: string, locale: string) {
  const localeContentMap: {[x: string]: string} = {
    'en-AU': 'australia',
    'en-GB': 'unitedkingdom',
  };
  const contentSuffix = localeContentMap[locale];

  return `${url}${
    contentSuffix
      ? `?utm_campaign=shopify_capital&utm_content=capital-${contentSuffix}&utm_medium=brochure&utm_source=dotcom`
      : ''
  }`;
}

export const handle: Handle = {
  driftBot({site}) {
    return ['en', 'en-AU', 'en-CA', 'en-GB', 'es', 'fr'].includes(site.locale);
  },
  metadata: {
    pageCategory: 'Manage',
    pageGroup: 'product',
    pageSubtopic: 'capital',
    pageTopic: 'payments',
    pagePath: '/capital',
  },
  /**
   * Need to reset the theme here because having a `handle` at this level
   * overwrites the lending theme
   */
  theme: Theme.FinancialServices,
  /**
   * To allow FullWidthCard's pseudo elements to be wider than the viewport to
   * guarantee the bg color goes edge-to-edge
   */
  mainClass: 'financial-services overflow-hidden relative z-0',
};

export {loader} from '@/utils/server/loader/loader';

export default function Capital() {
  const {site, localeImage} = useTranslations();
  const {t} = useSharedTranslations('pages/capital/content');
  const {t: sharedSettings} = useSharedTranslations('pages/capital/settings');
  const images = sharedSettings('images');

  const locale = useIntlLocale();
  const isUS = site.locale === 'en' || site.locale === 'es';
  const isEnNonUs =
    site.locale === 'en-AU' ||
    site.locale === 'en-CA' ||
    site.locale === 'en-GB';
  const hero = t('hero');
  const stats = t('stats');
  const outcomes = t('outcomes');
  const outcomes2 = t('outcomes2');
  const comparisonTable = t('comparisonTable');
  const sectionHeader2 = t('sectionHeader2');
  const conversion = t('conversion');
  const recommendations = t('recommendations');
  const faq = t('faq');
  const disclaimers = t('disclaimers');
  const maxWidthClass = 'max-w-[832px]';
  const commonBgGradient =
    'relative before:absolute before:h-[756px] before:w-[756px] before:z-n1 before:blur-[140px] before:bg-light-green before:rotate-45 before:select-none before:pointer-events-none';

  function getStatsHeading(metric: StatsMetric, i: number) {
    const {rawMetric, minimumSignificantDigits = 2} = metric;

    if (!rawMetric) {
      return metric.headingHtml;
    }

    /**
     * Always use USD here and always format symbol as `$`. In order to do that
     * we need to force `part.value` to be `$` when `part.type === 'currency'`.
     * Because some locales will prepend `US$` (like `ca` and `ca-FR`). Doing
     * it this way allows local currency styles to be applied, only changing the
     * symbol. Leaving some locales free to put the symbol after the number etc.
     */
    return t(`stats.metrics.${i}.headingHtml`, {
      metric: new Intl.NumberFormat(locale, {
        currency: 'USD',
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: 0,
        minimumSignificantDigits,
        notation: 'compact',
        style: 'currency',
      })
        .formatToParts(t(`stats.metrics.${i}.rawMetric`))
        .reduce(
          (result, part) =>
            `${result}${part.type === 'currency' ? '$' : part.value}`,
          '',
        ),
    });
  }

  /**
   * In order to avoid creating a custom Hero, I opted for injecting the
   * disclaimer text with a pseudo element. It's a hack and not ideal. This
   * content is not readable by screen readers, that is why it is duplicated
   * further down in a `<p class="sr-only">` element.
   */
  const injectDisclaimer = `[data-component-name=hero] :has(> [data-component-name=button-group])::after {
    content: "${hero.disclaimerText}";
    color: #71717A;
    font-size: var(--fs-font-size-body-small);
    line-height: var(--fs-line-height-body-small);
    letter-spacing: var(--fs-letter-spacing-body-small);
    font-weight: var(--fs-font-weight-body-small);
  }`;

  return (
    <PageLayout
      header={
        site.locale === 'en'
          ? (props) => FinancialServicesHeader(props, 'capitalSmallBusiness')
          : (props) => <Header {...props} />
      }
      footer={
        <Footer colorScheme={ColorSchemeEnum.Dark} className="bg-black" />
      }
    >
      <style dangerouslySetInnerHTML={{__html: injectDisclaimer}}></style>
      <HeroFloatingImage
        headingGroupProps={{
          kicker: hero.kicker,
          headingHtml: hero.headingHtml,
          subheadHtml: hero.subheadHtml,
        }}
        mode="light"
        images={[
          {
            alt: hero.image.alt,
            src: localeImage(images.hero.src),
            srcSet: localeImage(images.hero.srcSet),
          },
        ]}
        ctas={{
          buttons: [
            {
              href: getUrlWithUtm(hero.link.url, site.locale),
              text: hero.link.text,
              componentName: 'check-eligibility',
            },
          ],
          disclaimerHtml: hero.signup.disclaimerHtml,
        }}
        className={`pt-32 md:pt-44 ${commonBgGradient} before:top-[378px] before:left-[calc(100vw-378px)]`}
        displayPromoBanner
      />
      <p className="sr-only">{hero.disclaimerText}</p>
      <Section topSpacing="2xl" bottomSpacing="2xl">
        <SectionHeader size="t3" headingHtml={stats.headingHtml} />
        <LogoGroupMarquee
          logos={images.logos}
          direction="rtl"
          isResponsive
          pauseOnHover
          className="container"
        />

        <div className="container overflow-hidden">
          <WistiaVideoPlayer
            classNames={{
              wrapper:
                'lendingVideo aspect-video rounded-2xl overflow-hidden w-auto md:max-w-[1260px] ms-auto me-auto',
            }}
            videoId={images.video.id}
            autoPlay={false}
            aspectRatio="16/9"
            image={{
              srcSet: images.video.srcSet,
            }}
          />
        </div>
        <CardGrid withContainer={true} numberOfColumns={stats.metrics.length}>
          {stats.metrics.map((metric: StatsMetric, i: number) => (
            <Card
              key={`stats-${i}`}
              type="topDivider"
              className="border-black/20 [&_p]:text-body-lg [&_h3]:text-t4"
              size="small"
              headingGroup={{
                headingAs: 'h3',
                headingHtml: getStatsHeading(metric, i),
                subheadHtml: metric.subheadHtml,
              }}
            />
          ))}
        </CardGrid>
      </Section>
      <Section topSpacing="2xl" className="pb-xl">
        <SectionHeader size="t3" headingHtml={outcomes.headingHtml} />
        <CardGrid
          className="gap-0 md:gap-6"
          numberOfColumns={outcomes.blocks.length}
          withContainer={true}
        >
          {outcomes.blocks.map((block: CardProps, i: number) => (
            <Card
              key={`outcomes1-${i}`}
              className="[&_h3]:text-t6 py-xl md:p-xl"
              size="small"
              image={{src: images.outcomes[i], alt: block.image?.alt}}
              headingGroup={{
                headingAs: 'h3',
                headingHtml: block.headingGroup?.headingHtml,
                subheadHtml: block.headingGroup?.subheadHtml,
              }}
            />
          ))}
        </CardGrid>
        <div className="container text-center">
          <Button
            className="mb-6"
            href={getUrlWithUtm(outcomes.link.url, site.locale)}
            componentName="check-eligibility"
          >
            {outcomes.link.text}
          </Button>
        </div>
      </Section>
      <Section
        className={`pt-xl ${commonBgGradient} before:top-[264px] before:left-[-378px]`}
        bottomSpacing="2xl"
      >
        <SectionHeader
          className={cn(maxWidthClass, '[&_p]:text-body-lg')}
          headingHtml={outcomes2.heading}
          subheadHtml={outcomes2.subhead}
          size="t3"
        />
        <SideBySide
          gutter="right"
          image={{
            alt: outcomes2.image.alt,
            src: localeImage(images.outcomes2.src),
            srcSet: localeImage(images.outcomes2.srcSet),
            className: 'md:mr-0',
          }}
        >
          <div className="grid gap-y-xl mt-4 md:mt-0">
            {outcomes2.blocks.map(
              (
                {heading, contentHtml}: {heading: string; contentHtml: string},
                index: number,
              ) => (
                <Card
                  className="md:flex-col md:gap-y-4 [&_img]:w-[30px] [&_h3]:text-t6"
                  key={`card-${index}`}
                  size="small"
                  type="default"
                  horizontal={true}
                  headingGroup={{
                    headingAs: 'h3',
                    headingHtml: heading,
                    subheadHtml: contentHtml,
                  }}
                  image={{
                    src: images.outcomes2.icon,
                    className: 'w-8',
                  }}
                />
              ),
            )}
          </div>
        </SideBySide>
        <div className="flex flex-col items-center">
          <Button
            className="mb-6"
            href={outcomes.link.url}
            componentName="check-eligibility"
          >
            {outcomes.link.text}
          </Button>
        </div>
      </Section>
      <Section className="bg-deep-green" mode="dark">
        <SectionHeader
          size="t3"
          mode="dark"
          headingHtml={t('sectionHeader').heading}
        />
        <ComparisonTable
          caption={comparisonTable.caption}
          colHeaders={comparisonTable.colHeaders}
          rows={comparisonTable.rows}
          tableContainerClassName="border-none rounded-xl md:rounded-2xl"
          tbodyClassName="relative bg-white text-black md:before:w-9 md:before:h-full md:before:left-0 md:before:top-0 md:before:bg-inherit md:before:absolute md:after:w-9 md:after:h-full md:after:right-0 md:after:top-0 md:after:bg-inherit md:after:absolute"
          headClassName="bg-light-green"
          headHeaderClassName="text-black font-normal"
          rowClassName="border-b-2 border-shade-20 last:border-none"
          firstRowClassName="md:pt-[6px]"
          lastRowClassName="md:pb-[6px]"
          rowHeadClassName="font-normal md:font-normal md:h-[56px]"
          rowHeaderHiliteColor="bg-lime"
          checkIconClassName="[&_path]:fill-aloe-70"
        />
      </Section>
      <Section>
        <SectionHeader
          size="t3"
          className="[&_p]:text-body-lg"
          headingHtml={sectionHeader2.heading}
          kicker={sectionHeader2.kicker}
          subheadHtml={sectionHeader2.subhead}
        />
      </Section>
      <Testimonials
        isLooping
        className="mb-4xl [&_blockquote>p]:tracking-[-0.88px]"
        testimonials={t('testimonials').map(
          (testimonial: TestimonialProps, idx: number) => {
            const data = {
              quoteHtml: testimonial.quoteHtml,
              author: testimonial.author,
              authorTitle: testimonial.authorTitle,
              brand: testimonial.brand,
              image: {
                alt: testimonial.image?.alt,
                src: images.testimonials[idx].src,
                srcSet: images.testimonials[idx].srcSet,
                className: 'rounded-2xl',
                pictureClassName: 'w-[404px] h-[404px]',
              },
            };

            return testimonial.link
              ? {
                  ...data,
                  link: {
                    text: testimonial.link?.text,
                    href: testimonial.link?.href,
                  },
                }
              : data;
          },
        )}
      />
      <FullWidthCard
        className="overflow-visible bg-deep-green mt-2xl before:absolute before:bottom-1/2 before:left-1/2 before:-translate-x-[51vw] before:w-[102vw] before:h-[782px] before:z-n1 before:bg-[linear-gradient(0deg,#DBE7D4_0%,#F4F4F5_59.25%)] after:absolute after:bottom-0 after:left-1/2 after:-translate-x-[51vw] after:w-[102vw] after:h-1/2 after:z-n1 after:bg-shade-100"
        image={{
          alt: conversion.image.alt,
          src: localeImage(images.conversion.src),
          srcSet: localeImage(images.conversion.srcSet),
          className: 'rounded-t-2xl sm:rounded-r-none sm:rounded-l-2xl',
        }}
      >
        <>
          <Card
            mode="dark"
            headingGroup={{
              kicker: conversion.kicker,
              headingHtml: conversion.heading,
              subheadHtml: conversion.subhead,
            }}
            button={{
              href: getUrlWithUtm(
                conversion.cta.buttonPrimary.url,
                site.locale,
              ),
              children: conversion.cta.buttonPrimary.text,
              intent: 'primary',
              mode: 'dark',
              componentName: 'check-eligibility',
            }}
          />
        </>
      </FullWidthCard>
      <Section bottomSpacing="2xl" mode="dark">
        <SectionHeader
          size="t2"
          headingHtml={t('sectionHeader4').heading}
          mode="dark"
        />
        <CardGrid numberOfColumns={3} withContainer={true}>
          {t('cards').map((card: CardProps, idx: number) => (
            <Card
              key={`card-${idx}`}
              className="bg-light-green [&_h3]:mb-xl"
              type="outline"
              size="small"
              headingGroup={{
                headingAs: 'h3',
                headingHtml: card.headingGroup?.headingHtml,
                subheadHtml: card.headingGroup?.subheadHtml,
              }}
              link={{
                href: card.link?.href,
                text: card.link?.text,
                /**
                 * Need to override foundation/styles.css targeting this because
                 * of mode="dark" on the Section
                 */
                className: 'text-fs-black',
              }}
            />
          ))}
        </CardGrid>
      </Section>
      {isUS && (
        <Section topSpacing="2xl" bottomSpacing="2xl" mode="dark">
          <div className="container md:grid md:grid-cols-12">
            <Typography
              className="mb-2xl md:col-span-5"
              as="h2"
              size="t2"
              mode="dark"
            >
              {recommendations.headingGroup.headingHtml}
            </Typography>
            <div className="md:flex md:flex-col md:col-span-6 md:col-start-7">
              {recommendations.cards.map(
                (card: CardProps, idx: number, arr: CardProps[]) => (
                  <React.Fragment key={`creditOutcome-${idx}`}>
                    <Link
                      href={card.link?.href}
                      className="block text-t6 md:text-t5 mb-md md:mb-sm [&>span]:no-underline [&_svg]:opacity-100 [&_svg]:ml-5 [&_svg]:mb-1"
                      mode="dark"
                      size="large"
                    >
                      {card.link?.text}
                    </Link>
                    <Typography
                      as="p"
                      className={cn(
                        '[&_a]:font-normal mb-xl md:pb-sm md:mb-sm',
                        idx < arr.length - 1 &&
                          'md:border-b-2 md:border-white/15',
                      )}
                      mode="dark"
                    >
                      {card.headingGroup?.subheadHtml}
                    </Typography>
                  </React.Fragment>
                ),
              )}
            </div>
          </div>
        </Section>
      )}
      <Section topSpacing="2xl" bottomSpacing="2xl" mode="dark">
        <SectionHeader size="t2" mode="dark" headingHtml={faq.heading} />
        <Faq
          mode="dark"
          accordionItems={faq.accordionItems.map(
            (item: AccordionProps, idx: number) => ({
              heading: item.heading,
              content: item.content,
              id: `faq-${idx}`,
            }),
          )}
        />
      </Section>
      <Disclaimers
        className="text-body-sm"
        disclaimers={isEnNonUs ? disclaimers.enNonUS : disclaimers.default}
      />
    </PageLayout>
  );
}
